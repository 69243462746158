<template>
  <div id="productlist">
    <!-- <router-link to="/collection">
      <el-button type="primary" icon="el-icon-plus" style="margin-bottom:30px"
        >添加产品</el-button
      >
    </router-link> -->

    <template v-if='tableData'>
      <el-table
        v-loading="loading"
        :header-cell-style="getRowClass"
        :data="tableData"
        border
        style="width: 100%"
      >
        <el-table-column prop="id" label="ID" width="120"> </el-table-column>

        <el-table-column prop="type" label="来源" width="120">
           <template slot-scope="scope">
              <a :href="scope.row.source" target="_blank" class="href">{{scope.row.type}}</a>
           </template>
        </el-table-column>

        <el-table-column prop="productid" label="产品图" width="120">
               <template slot-scope="scope">
                 <img v-if='scope.row.pic1' style="width:40%" :src="scope.row.pic1" alt="">
                 <span v-else>暂无</span>
               </template>
        </el-table-column>

        <el-table-column :show-overflow-tooltip="true" prop="title" label="标题" width="">
          
            <!-- <template slot-scope="scope">
                <el-popover
                  placement="top-start"
                  title="产品名称"
                  width="300"
                  trigger="hover"
                >
                  <div>{{ scope.row.title }}</div>
                  <span slot="reference">{{
                    scope.row.title.substr(0,20) + "..."
                  }}</span>
                </el-popover>
            </template> -->

           </el-table-column>

        <el-table-column prop="jiage" label="价格" width="120">
        </el-table-column>

        <el-table-column prop="yuanjia" label="原价" width="120">
        </el-table-column>

        <el-table-column prop="status" label="操作/状态" width="120">
          <template slot-scope="scope">
          

              <el-button  v-if='scope.row.status!=5' size="small" type="primary" @click="add(scope.row.id)" icon="el-icon-s-promotion">上架</el-button>

               <el-tag v-else type="success" disable-transitions> 已上架</el-tag>
          </template>
        </el-table-column>
      </el-table>

      <div class="page">
        <el-pagination
          background
          v-loading="loading"
          layout="prev, pager, next"
          :current-page="page"
          :page-size="20"
          :total="total"
          @current-change="change"
        >
        </el-pagination>
      </div>
    </template>
  </div>
</template>
<style lang="scss">
   .href{
         color: #606266;
   }
</style>
<script>
export default {
  name: "ProductList",
  data() {
    return {
      tableData: [
       
      ],
      loading: true,
      total:0,
      page:0,
    };
  },
  methods: {
    getRowClass({ row, column, rowIndex }) {
      console.log(row, column);
      if (rowIndex === 0) {
        return "background:#F5F7FA;color:#353535;";
      } else {
        return "";
      }
    },
    change(val){
      this.page = val;
      this.getlist()
    },
    getlist(){
     
      this.axios.get(this.api.product,{
        params:{
          page:this.page
        }
      })
      .then((res)=>{
        this.tableData=res.data;
        this.total=Number(res.count);
        this.loading = false;
      })
    },

    add(id){
      this.axios.get(this.api.pushProduct,{
        params:{
          id,
        }
      })
      .then((res)=>{
        if(res.code==1){
           this.$message({
            message: res.msg,
            type: 'success',
          });
        }else{
           this.$message.error(res.msg);
        }
      })
    }
  },
  created() {
    this.page+=1;
    this.getlist()
  }
};
</script>